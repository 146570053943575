import { graphql, useStaticQuery } from 'gatsby';
import { FC } from 'react';
import { Col, Row } from 'react-awesome-styled-grid';

import CaptionedTitle from '../CaptionedTitle';
import Markdown from '../Markdown';
import PageHeader from '../PageHeader';

const AboutPageHeader: FC = () => {
  const { data } = useStaticQuery(query);
  const {
    header: { title, caption, description }
  } = data.edges[0].node;

  return (
    <PageHeader>
      <Row>
        <Col lg={8}>
          <CaptionedTitle as="h2" caption={caption}>
            {title}
          </CaptionedTitle>
        </Col>
        <Col lg={4}>
          <Markdown content={description} />
        </Col>
      </Row>
    </PageHeader>
  );
};

const query = graphql`
  query {
    data: allAboutPageYaml {
      edges {
        node {
          header {
            title
            caption
            description
          }
        }
      }
    }
  }
`;

export default AboutPageHeader;
